import React from "react"
import { Router } from "@reach/router"

import SelectTemplate from "./selectTemplate/"
import {
  PageOptions,
  navigationRoutes,
} from "@src/localization/en/createCampaign"

const CreateCampaign = (): React.ReactElement => {
  return (
    <Router>
      <SelectTemplate path={navigationRoutes[PageOptions.PICKTEMPLATE]} />
    </Router>
  )
}

export default CreateCampaign
